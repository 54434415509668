//@import "swiper/modules/effect-fade/effect-fade.scss";
@import "swiper/swiper-bundle.css";

@import "./components/animations";
@import "./components/_cookieconsent.css";
@import "../../node_modules/select2/src/scss/core.scss";

@import "./settings/variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Lary Fary";
  font-weight: 400;
  src: url(../fonts/lary_fary-webfont.woff2) format("woff2");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-size: 16px;
    @apply text-body font-primary;
  }
  h1,
  .h1,
  h1 p,
  .h1 p {
    font-size: 1.6rem;
    line-height: 1.37;
    @media screen and (min-width: 768px) {
      font-size: 2.375rem;
    }
  }
  h2,
  .h2,
  h2 p,
  .h2 p {
    line-height: 1.43;
    font-size: 1.375rem;
    @media screen and (min-width: 768px) {
      font-size: 1.875rem;
    }
  }
  h3,
  .h3,
  h3 p,
  .h3 p {
    font-size: 1.15rem;
    line-height: 1.85;
    @media screen and (min-width: 768px) {
      font-size: 1.31rem;
    }
  }
  p {
    line-height: 1.8;
  }
  .large {
    font-size: 1rem;
    line-height: 2.16;
    @media screen and (min-width: 768px) {
      font-size: 1.125rem;
    }
  }
  .small {
    @apply text-sm;
    line-height: 2.07;
  }
  .row {
    @apply flex flex-wrap -ml-4 -mr-4;
    & > * {
      @apply w-full max-w-full;
    }
  }
  .col {
    @apply pr-4 pl-4 flex-1;
    min-width: 1px;
  }
  [class^="col"] {
    flex: 0 0 auto;
  }
  b {
    @apply font-semibold;
  }
  .list-initial {
    ul {
      list-style: initial;
    }
  }
}

@layer components {
  .c-btn {
    @apply bg-primary text-white font-medium px-7 rounded-full py-2 md:py-4 uppercase hover:bg-secondary transition-colors inline-flex text-sm md:text-h3 items-center hover:text-primary leading-snug;
    &--secondary {
      @apply bg-secondary text-body hover:bg-primary hover:text-white;
    }
    &--outline {
      @apply border-2 border-white hover:bg-white hover:text-primary;
    }
    &--large {
      border-radius: 20px;
    }
  }
  .c-select {
    @apply block md:hidden border border-primary rounded-l-2xl py-2 w-full px-2 font-semibold font-primary text-primary appearance-none;
    background: transparent;
    background-image: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 13px);
    background-position-y: 13px;
  }
  .c-nav-item {
    @apply font-medium px-5 mobile:rounded-full py-3 uppercase hover:bg-primary transition-colors inline-flex text-sm items-center hover:text-white;
    &.is-active {
      @apply text-white bg-primary;
    }
  }
  .c-bubble {
    @apply relative inline-block;
    &__svg {
      @apply absolute h-full;
      width: calc(100% + 120px);
      transform: translateX(-60px);
      @media screen and (max-width: 768px) {
        width: calc(100% + 80px);
        transform: translateX(-40px);
      }

      &--spec {
        width: calc(100% + 0px);
        transform: translateX(0px);
      }
    }
    &__content {
      @apply relative z-10 py-7;
      padding-right: 80px;
      @media screen and (max-width: 768px) {
        padding-right: 50px;
      }
    }
  }
  .icon {
    @apply inline-flex fill-current stroke-current stroke-0;
    width: 1em;
    height: 1em;
  }
  .c-wysiwyg {
    p,
    ul,
    h1,
    h2,
    h3,
    h4,
    img {
      @apply mb-8;
    }
    h1,
    h2,
    h3,
    h4 {
      @apply text-primary;
    }
    p:last-child {
      @apply mb-0;
    }
    a {
      @apply underline hover:text-primary;
    }
    ul {
      padding-left: 22px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    ul li {
      list-style-type: disc;
      @apply py-2;
      @apply pl-2;
    }
    &--list {
      ul li {
        list-style-type: disc;
        @apply py-1;
        @apply pl-1;
      }
    }
    &.large {
      ul {
        li {
          @apply text-[20px];
        }
      }
    }
    em {
      @apply text-primary;
    }
  }
  .c-tab {
    @apply text-primary uppercase font-semibold relative pb-7 cursor-pointer lg:text-base text-sm;
    &.is-active:after,
    &:hover:after {
      @apply h-2 rounded-2xl bg-primary w-full absolute bottom-0 left-0;
      content: "";
    }
  }
  .c-radio-group input ~ label svg {
    @apply hidden;
  }
  .c-radio-group input:checked ~ label {
    @apply text-primary border-primary relative z-10;
    svg {
      @apply inline-block;
    }
  }
  .c-radio-group {
    @apply inline-flex;
  }
}

.map-perex p {
  @apply leading-tight;
}

.is-menu-open #navbar-sticky,
.is-menu-open .navbar-overlay {
  @apply block;
}

.js-tab-content:not(.is-active) {
  @apply hidden;
}

.select2-container--default .select2-selection--single {
  @apply border-gray-500 rounded-md;
}

.select2-container .select2-selection--single,
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  @apply h-10;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  @apply leading-10 pl-4 font-semibold text-primary;
}

.select2-container
  .select2-results__options
  .select2-results__option--highlighted,
.select2-container
  .select2-results__options
  .select2-results__option--selected {
  @apply bg-primary;
}
