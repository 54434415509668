:root {
  --color-primary: #482780;
  --color-secondary: #F6D14C;
  --color-primary-light: #7e68a5;
  --font-primary: 'Poppins', sans-serif;
  --font-secondary: 'Lary Fary', sans-serif;
  --color-body: #222222;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-error: 255 3 46;
  --color-info: 16 183 255;
  --color-success: 75 239 6;
  --color-warning: 240 132 22;
  --color-grey-400: #f2f2f2;
  --color-grey-500: 179 179 179;
  --color-grey-600: 126 126 126;
  --color-grey-700: 36 36 36;
  --color-grey-800: 24 24 24;
  --color-grey-900: 14 14 14;
  --color-link: var(--color-primary);
  --color-border: var(--color-grey-700);
  --rounded-o-list__icon: 100%;

   // LIGHTMODE
   --color-bodyBackgroundLightMode: var(--color-white);
   --color-boxBackgroundLightMode: var(--color-grey-400);
   --color-borderLightMode: 227 227 227;


// ============= COMPONENTS ============== //

  // BUTTONS
  --color-buttonPrimaryTextColor: var(--color-white);
  --color-buttonSecondaryTextColor: var(--color-white);
  --font-button: var(--font-primary);
  --font-buttonTransform: uppercase; 

  // HEADINGS
  --color-heading: var(--color-grey-800);
  --font-heading: var(--font-primary);
  --font-headingWeight: 500;
  --font-headingTransform: uppercase; 
  --font-headingStyle: normal;


  // MASONRY GRID
  --masonry-grid-template-areas: "box_4 box_7""box_4 box_8""box_2 box_8""box_5 box_6""box_5 box_9""box_3 box_9";
  --masonry-grid-height: 100%;
  --masonry-before-padding: calc(42.25% - 1px);
  --masonry-before-padding-content: calc(116.33333% - 1px);

  // BOXED COMPONENT
  --border-boxed: 0px;

  // HEADER SEARCH ACTIVE RESPONSIVE
  --header-search-active-responsive: 67px;


}

[theme="light"]{
 --color-border: var(--color-grey-500);
}